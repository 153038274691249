import React from 'react';

const test = () => {
  return (
    <div>
      <header className="header">Some text</header>
    </div>
  );
};

export default test;
